import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  FacebookShareCount,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  RedditShareCount,
  EmailShareButton,
  EmailIcon
} from 'react-share'

const ShareButtons = ({title, url, tags}) => {

    return(
        <div>
          <FacebookShareButton url={url} >
            <FacebookIcon  size={40} round={true}/>
            <FacebookShareCount/>
          </FacebookShareButton>

          <FacebookMessengerShareButton url={url} >
            <FacebookMessengerIcon  size={40} round={true}/>
          </FacebookMessengerShareButton>

          <TwitterShareButton url={url} title={title} hashtags={tags}>
            <TwitterIcon  size={40} round={true} />
          </TwitterShareButton>

          <RedditShareButton url={url} title={title} >
            <RedditIcon  size={40} round={true} />
            <RedditShareCount/>
          </RedditShareButton>

          <WhatsappShareButton url={url} title={title}>
            <WhatsappIcon  size={40} round={true}/>
           </WhatsappShareButton>

          <EmailShareButton url={url} title={title}>
            <EmailIcon  size={40} round={true}/>
          </EmailShareButton>

        </div>
      )

}
export default ShareButtons
